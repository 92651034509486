import { useFetcher } from "@remix-run/react";
import { useEffect, useState } from "react";
import type { UseFormReturn } from "react-hook-form";
import { useForm } from "react-hook-form";

import { FormResponseSchema } from "../models/FormResponse.js";
import type { ZodIssue } from "../models/ZodIssue.js";
import type { FormMutationOptions } from "./useFormContext.js";

/**
 * Return type of useFormMutation
 */
export interface FormMutationState {
  resultData: any;
  formId: string;
  options?: FormMutationOptions;
  mutating: boolean;
  fetcher: ReturnType<typeof useFetcher>;
  formError?: string;
  formIssues?: ZodIssue[];
  setFormIssues: (issues?: ZodIssue[]) => void;
  setFormError: (error?: string) => void;
  clearIssues: () => void;
  methods: UseFormReturn;
  submit: (data: any) => void;
}

/**
 * A hook that wraps Remix useFetcher and adds some additional functionality This should be used for
 * all mutations of the app
 */
export const useFormMutation = (
  formId: string,
  options?: FormMutationOptions
): FormMutationState => {
  const fetcher = useFetcher();

  const [resultData, setResultData] = useState<any>(undefined);
  const [formError, setFormError] = useState<string>();
  const [formIssues, setFormIssues] = useState<ZodIssue[]>();

  const reactHookMethods = useForm({
    defaultValues: options?.defaultValues,
    values: options?.values,
    shouldUnregister: options?.shouldUnregister,
  });

  const onChange = options?.onChange;

  useEffect(() => {
    if (onChange) {
      const { unsubscribe } = reactHookMethods.watch((d) => {
        onChange(d);
      });
      return () => unsubscribe();
    } else {
      return;
    }
  }, [reactHookMethods.watch, onChange]);

  const clearIssues = () => {
    setFormError(undefined);
    setFormIssues(undefined);
  };

  // Effect for After Form Submission Successful
  useEffect(() => {
    // Fetcher is idle and has data which means the form has been submitte and loaders have loaded
    if (fetcher.state === "idle" && fetcher.data) {
      const formResponse = FormResponseSchema.parse(fetcher.data);

      if (formResponse.success) {
        // Clear Errors
        setFormError(undefined);
        setFormIssues(undefined);

        /**
         * Fetcher has submited at least once Figure out which handler to use based on the actionId
         */
        const { onSuccess } =
          (formResponse.actionId
            ? options?.actionHandlers?.[formResponse.actionId]
            : options?.handlers) ?? {};

        // Handle onSuccess
        onSuccess?.(formResponse);

        const r = formResponse.result;

        //   Add data to resultData
        if (formResponse.actionId) {
          setResultData((d: any) => ({
            ...d,
            [formResponse.actionId!]: r,
          }));
        } else if (formResponse) {
          setResultData(formResponse.result);
        }
      } else {
        // Form submission failed
        setFormError(formResponse.error ?? undefined);
        setFormIssues(formResponse.issues ?? undefined);

        // Clear Result Data
        setResultData(undefined);
      }
    }
  }, [fetcher.state]);

  const submit = (data: any) => {
    fetcher.submit(
      {
        __formId: formId,
        ...data,
      },
      {
        method: "post",
        action: options?.action,
      }
    );
  };

  return {
    resultData,
    formId,
    options,
    mutating: fetcher.state !== "idle",
    fetcher,
    formError,
    formIssues,
    setFormIssues,
    setFormError,
    clearIssues,
    methods: reactHookMethods,
    submit,
  };
};
