import z from "zod";

import { ZodIssueSchema } from "./ZodIssue.js";

export const FormErrorResponseSchema = z.object({
  actionId: z.string().nullish(),
  success: z.literal(false),
  error: z.string().nullish(),
  // Zod Issues
  issues: z.array(ZodIssueSchema).nullish(),
});

export type FormErrorResponse = z.infer<typeof FormErrorResponseSchema>;
