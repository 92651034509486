import { z } from "zod";

export const ZodIssueSchema = z.object({
  message: z.string(),
  code: z.string(),
  // Array of strings or numbers
  path: z.array(z.union([z.string(), z.number()])).nullish(),
});

export type ZodIssue = z.infer<typeof ZodIssueSchema>;
